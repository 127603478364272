
<template>
  <div class="ape-constructor-equipped-trait">
    <ApeConstructorTraitCard
      :item="item"
      :is-equipped="true"
      :show-category="true"
    >
      <template #actions>
        <a
          href="#"
          class="ape-constructor-trait-card-action ape-constructor-trait-card-action-change"
          @click.prevent="$emit('change')"
        >Change</a>
        <a
          v-if="canDeEquip"
          href="#"
          class="ape-constructor-trait-card-action ape-constructor-trait-card-action-de-equip"
          @click.prevent="$emit('deEquip')"
        >De Equip</a>
      </template>
    </ApeConstructorTraitCard>
  </div>
</template>
<script>import { useApeConstructorStore } from "@/store/ape-constructor";
import { computed, defineComponent } from "vue-demi";
import ApeConstructorTraitCard from "../ApeConstructorTraitCard/ApeConstructorTraitCard.vue";

export default defineComponent({
  components: { ApeConstructorTraitCard },
  props: {
    item: { type: Object, default: () => ({}) }
  },
  setup(props) {
    const store = useApeConstructorStore()
    const canDeEquip = computed(() => store.canDeEquipTrait(props.item))
    return { canDeEquip }
  }
})
</script>
<style lang="scss">
.ape-constructor-equipped-trait {
  padding: 5px;
  padding-top: 14px;
  .ape-constructor-trait-card {
    &-actions {
      flex-direction: column;
      align-items: stretch;
    }
    &-action {
      &:hover {
        filter: brightness(0.8);
      }
      &-change {
        background-color: var(--ape-constructor-primary);
      }
      &-de-equip {
        background-color: var(--ape-constructor-gray);
        margin-top: 10px;
      }
    }
  }
}
</style>

