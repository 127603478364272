<template>
  <div class="ape-constructor-ape-select-wrapper">
    <VSelect
      class="ape-constructor-ape-select custom-select"
      :options="apes"
      :clearable="false"
      :model-value="currentActiveApe.originalId"
      :reduce="(ape) => ape.originalId"
      :searchable="false"
      @search="onSearch"
      @close="$emit('close')"
      @update:modelValue="onApeChange"
    >
      <template #option="option">
        <span
          v-sound.click
          class="ape-constructor-ape-select-option"
        >
          <img
            class="ape-constructor-ape-select-image"
            :src="option.image"
            alt
          >
          <span class="ape-constructor-ape-select-name"> {{ option.name }}</span>
        </span>
      </template>
      <template #selected-option="apeId">
        <span> {{ apeId.label ? `Ape #${apeId.label}` : currentActiveApe.name }}</span>
      </template>
    </VSelect>
  </div>
</template>
<script>
import { useApeConstructorStore } from "@/store/ape-constructor";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import defaultApes from "@/config/default-apes";
import { computed, defineComponent, ref } from "vue"
import { useRouter } from "vue-router";
import VSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
export default defineComponent({
    components: { VSelect },
    setup() {
        const searchString = ref('')
        const walletCollectionStore = useWalletCollectionStore()
        const apeConstructorStore = useApeConstructorStore()
        let apes
        if( walletCollectionStore.apes.length > 0){
          apes = computed(() => walletCollectionStore.apes.filter(ape => {
            if (apeConstructorStore.ape.id === ape.id) return false
            if (ape.pending) return
            if (searchString.value) {
                return ape.id?.toString().includes(searchString.value)
            }
            if(ape.disableCustomization) return false
            return true
        }))
        } else {
          apes = computed(() => defaultApes)
        }
        const onSearch = (s) => {
            console.log(s)
            searchString.value = s
        }
        const router = useRouter()
        const onApeChange = (oId) => {
          if( walletCollectionStore.apes.length > 0){
            const ape = walletCollectionStore.apes.find(ape => ape.originalId === oId)
            router.push({ name: "ApeConfigurator", params: { id: ape?.id } })
          } else {
            router.push({ name: "ApeConfigurator", params: { id: oId } })
          }
        }
        const currentActiveApe = computed(() => apeConstructorStore.ape)
        return { apes, onSearch, currentActiveApe, onApeChange }
    }
})
</script>
<style lang="scss">
.ape-constructor-ape-select {
    width: 100%;
    --vs-dropdown-option-padding: 5px 5px;
    font-weight: bold;

    &-label {
        opacity: 0.5;
        text-transform: uppercase;
        margin-bottom: 5px;
    }
    &-option {
        display: flex;
        align-items: center;
        font-size: 14px;
    }
    &-image {
        width: 40px;
        margin-right: 5px;
        border-radius: 5px;
    }
    &-name {
    }
}
</style>