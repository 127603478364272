import { useApi } from "@/composables/useApi";
import { Config } from "@/config";

export const useApiApeTraitsSearch = useApi(
  ({ genderId, currentPage, search, bodyTypeId }) => ({
    method: "GET",
    url: "/constructor/apes-traits-search",
    baseURL: Config.apeStorageUrl,
    params: {
      genderId,
      currentPage,
      searchString: search,
      furOrSkin: bodyTypeId
    },
  })
);
