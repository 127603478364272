<template>
  <div
    class="ape-constructor-search-wrapper"
  >
    <IShape
      width="83"
      class="ape-constructor-search-shape"
    />
    <div class="ape-constructor-search">
      <ISearch
        width="24"
        class="ape-constructor-search-icon"
      />
      <input
        v-model="searchModel"
        placeholder="Search for trait e.g. astronaut clothing, Red eyes"
        class="ape-constructor-search-input"
      >
      <a
        v-if="searchModel"
        href="#"
        class="ape-constructor-search-clear"
        @click.prevent="clearInput"
      >
        <IClose width="24" />
      </a>
    </div>
  </div>
</template>
<script>import { computed, defineComponent, ref } from "vue-demi";
import ISearch from "@/assets/icons/ape-constructor/ape-constructor-search.svg?inline"
import IShape from "@/assets/icons/ape-constructor/ape-constructor-input-shape.svg?inline"
import IClose from "@/assets/icons/close.svg?inline"

export default defineComponent({
  components: { ISearch, IShape, IClose },
  props: { value: String },
  setup(props, { emit }) {
    const searchModel = computed({
      get: () => props.value,
      set(val) {
        emit('update:value', val)
      }
    })
    const clearInput = () => {
      emit('update:value', '')
    }
    return { searchModel, clearInput }
  }
})
</script>
<style lang="scss">
.ape-constructor-search {
  position: relative;
  &-input {
    padding: 12px;
    padding-left: 56px;
    padding-right: 40px;
    background-color: #0d0d0d;
    color: #fff;
    border: none;
    outline: none;
    width: 100%;
    font-weight: bold;
    box-sizing: border-box;
    font-size: 16px;
    &::placeholder {
      color: #3d3e3f;
    }
  }
  &-clear {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    color: #3d3e3f;
    transition: 0.3s;
    font-size: 0;
    &:hover {
      color: #fff;
    }
  }
  &-icon {
    position: absolute;
    pointer-events: none;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    color: #3d3e3f;
  }
  &-wrapper {
    position: relative;
    border-top: 1px solid #3d3e3f;
    margin-bottom: 30px;
  }
  &-shape {
    position: absolute;
    top: 0;
    transform: translateY(-100%);
    right: 0;
  }
}
</style>