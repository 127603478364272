<template>
  <div class="ape-constructor-back">
    <a
      href="#"
      class="ape-constructor-back-button"
      @click.prevent="goBack"
    >
      <IArrowLeft width="7" />Back to my collection
    </a>
  </div>
</template>
<script>import { defineComponent } from "vue-demi";
import IArrowLeft from "@/assets/icons/ape-constructor/ape-constructor-arrow-left.svg?inline"
import { useRouter } from "vue-router";
export default defineComponent({
  components: { IArrowLeft },
  setup() {
    const router = useRouter()
    const goBack = () => {
      router.push({ name: "Collection" })
    }
    return { goBack }
  },
})
</script>
<style lang="scss">
.ape-constructor-back {
  margin-bottom: 16px;
  &-button {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    text-decoration: none;
    transition: 0.2s;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
    svg {
      margin-right: 10px;
    }
  }
  @media screen and (max-width: 760px) {
    &-button {
      font-size: 14px;
      svg {
        margin-right: 8px;
      }
    }
  }
}
</style>