export default [
  `Just finished customizing my @BullsApesProj  Ape and I'm loving it! 

Check out what I designed 🔥

#TheConfigurator #DynamicNFTs #BAP `,
  `Who said digital identity has to be boring? 

@BullsApesProj  #DynamicNFTs allow you to customize your own PFP that expresses your digital identity however you please. 

Try it now! 

#TheConfigurator #BAP`,
  `Looking for something different? 

@BullsApesProj #DynamicNFTs have over a trillion possible diff trait combinations! 

Get creative, design YOURS today and be one-of-a-kind ❤️ 

#TheConfigurator #BAP`,
  `So excited about my new custom PFP! 

Designing it with the help of the @BullsApesProj #TheConfigurator was a blast 🎉 

#DynamicNFTs #BAP`,
  `Hey, look at my awesome new Ape! 👀

My own personalized PFP made with the help of #TheConfigurator from @BullsApesProj  

What’s your favorite trait??

#DynamicNFTs #BAP`,
  `Static PFPs are so 2021😜

Design something truly amazing like I did with #TheConfigurator & express yourself digitally 🤩 

#DynamicNFTs #BAP @BullsApesProj`,
  `Just personalized my Ape with the #TheConfigurator from @BullsApesProj! 

Feels so good to own a PFP that’s truly mine. 

#BAP #DynamicNFTs`,
  `Wow, what an amazing experience customizing my PFP with #BAP! 

It’s truly unlike anything else out there. 

Get yours today from @BullsApesProj 

#TheConfigurator #DynamicNFTs`,
  `Who knew customizing a digital identity could be this fun? 

I just did it with the @BullsApesProj #TheConfigurator 

You should too! 
#BAP #DynamicNFTs`,

  `Just designed my very own #DynamicPFP using  #TheConfigurator from @BullsApesProj 

I'm loving the expression of creativity it gives me!

#BAP #DynamicNFTs`,
  `I customized this Ape with #TheConfigurator from @BullsApesProj!

You'll be amazed by how easy, fun…and addicting it is! 

#DynamicNFTs #BAP`,
  `If you're looking for something unique and special, try creating your own Dynamic PFP with #TheConfigurator from @BullsApesProj. 

How did I do?? 

#BAP #DynamicNFTs`,

  `I designed this Ape! 

Don’t be left behind! 

Grab your loot box and take control of your own digital identity with @BullsApesProj. 

#TheConfigurator #BAP #DynamicNFTs`,

  `I just unlocked the power of ‘Dynamic’ with @BullsApesProj. 💫

Don’t settle, design YOUR Ape PFP and share it with the world!🌎

#TheConfigurator #BAP #DynamicNFTs`,

  `Felt like putting my personal fingerprint on my online presence - designing a dynamic PFP with the help of @bullsapesproj. 

What do you think?? ✨ 

#DynamicNFTs  #TheConfigurator`,
];
