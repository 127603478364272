import { useApesStorageApi } from "@/api/useApesStorageApi";
import { getApeChangeCode } from "./ape-chage-code";
import { Config } from "@/config";
import { randomNum } from "../utils";
import twitterShareContent from "@/config/twitter-share-content";
import { captureError } from "../captureError";
import { useWeb3Store } from "@/store/web3";
import { useApeConstructorStore } from "@/store/ape-constructor";
export const shareApeTwitter = async () => {
  try {
    const customizationCode = getApeChangeCode();
    const { isPreview } = await useApesStorageApi({
      toast: { error: () => "Couldn't share an image, please try again" },
      throw: true,
    }).exec({
      url: "/constructor/request-preview",
      method: "POST",
      data: {
        customizationCode,
        wallet: useWeb3Store().walletAddress,
        apeId: useApeConstructorStore().ape?.id,
      },
    });
    const shareURL = `${Config.apeStorageUrl}/constructor/share?customizationCode=${customizationCode}&isPreview=${isPreview}`;
    const texts = twitterShareContent;
    // const currentText = texts[randomNum(0, texts.length - 1)];
    const currentText = texts[randomNum(0, texts.length - 1)];
    const encodedText = encodeURIComponent(currentText);
    // texts.forEach((text) => {
    //   const encodedText = encodeURIComponent(text);
    //   console.log(
    //     `https://twitter.com/intent/tweet?text=${encodedText}!&url=${shareURL}`
    //   );
    // });
    setTimeout(() => {
      window.open(
        `https://twitter.com/intent/tweet?text=${encodedText}&url=${encodeURIComponent(shareURL)}`,
        "_blank"
      );
    }, 100);
  } catch (err) {
    captureError(err);
  }
};
