<template>
  <div class="ape-constructor-navigation">
    <ApeConstructorMenu />
    <div class="ape-constructor-navigation-title">
      Current Ape
    </div>
    <div
      id="current-ape"
      class="ape-constructor-navigation-ape"
    >
      <div v-if="isGuest" class="ape-constructor-navigation-ape__image">
        <img
          :src="currentApe.image"
          alt="ape"
        >
        <div class="ape-constructor-navigation-ape__name">
          #{{ currentApe.originalId }}
        </div>
      </div>
      <div class="ape-constructor-navigation-ape__actions">
        <div
          v-click-outside="closeChanging"
          class="ape-constructor-ape__action-wrapper"
        >
          <ApeConstructorApeSelect
            v-if="isChanging"
            v-sound.click
            @close="closeChanging"
          />
          <a
            v-else
            v-sound.click
            href="javascript:void(0)"
            class="ape-constructor-navigation-ape__action ape-constructor-navigation-ape__action-change"
            @click.prevent="changeApe"
          >
            <IChange width="14" />Customize Another Ape
          </a>
        </div>
        <RouterLink
                    v-sound.click.excited
          class="ape-constructor-navigation-ape__action ape-constructor-navigation-ape__action-lootbox"
          :to="{ name: 'LootBox', query: { ape: currentApe.id } }"
        >
          Open LootBox
        </RouterLink>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, defineComponent, ref } from "vue"
import ApeConstructorApeSelect from "@/components/ApeConstructor/ApeConstructorApeSelect.vue"
import { useApeConstructorStore } from "@/store/ape-constructor"
import IChange from "@/assets/icons/change.svg?inline"
import ApeConstructorMenu from "../ApeConstructorMenu/ApeConstructorMenu.vue"
export default defineComponent({
  components: { ApeConstructorApeSelect, IChange, ApeConstructorMenu },
  setup() {
    const apeConstructorStore = useApeConstructorStore()

    const currentApe = computed(() => apeConstructorStore.ape || {})
    const isChanging = ref(false)
    const changeApe = () => {
      isChanging.value = true
    }
    const closeChanging = () => {

      isChanging.value = false
    }
    const isGuest = computed(() => !(apeConstructorStore.ape.id === 1 || apeConstructorStore.ape.id === 2))  

    return {
      closeChanging,
      isChanging,
      changeApe,
      currentApe,
      isGuest
    }
  }
})
</script>
<style lang="scss">
.ape-constructor-navigation {
  margin-bottom: 10px;
  padding-bottom: 20px;
  padding-left: 40px;
  box-sizing: border-box;
  &-title {
    text-transform: uppercase;
    opacity: 0.5;
    margin-bottom: 5px;
  }
  &-ape {
    display: flex;
    align-items: center;
    border-radius: 5px;
    &__image {
      max-width: 150px;
      margin-right: 10px;
      img {
        max-width: 100%;
        border-radius: 5px;
      }
    }
    &__name {
      text-align: center;
      margin-top: 5px;
    }
    &__action {
      height: 35px;
      box-sizing: border-box;
      padding: 5px 10px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 14px;
      width: 250px;
      transition: 0.2s;

      &-change {
        border: 1px solid var(--border);
        border-radius: 5px;
        background-color: var(--gray-light);
        svg {
          min-height: 0;
          margin-right: 5px;
        }
        &:hover {
          background-color: var(--border);
        }
      }
      &-lootbox {
        border: 1px solid var(--primary);
        color: var(--primary);

        margin-top: 10px;
        &:hover {
          background-color: var(--primary);
          color: #fff;
        }
      }
    }
  }
  @media screen and (max-width: 576px) {
    padding: 0 15px;

    width: 100%;
    &-title {
      text-align: center;
    }
    &-ape {
      flex-direction: column;
      align-items: center;
      &__actions {
        margin-top: 10px;
      }
    }
  }
}
</style>