<template>
  <div class="ape-constructor-search-items">
    <template v-if="items.length">
      <a
        v-for="item in items"
        :key="item.id"
        href="#"
        class="ape-constructor-search-item"
        @click.prevent="equipTrait(item)"
      >
        <ApeConstructorTraitCard
          :item="item"
          :show-category="true"
          :is-equipped="isEquipped(item)"
          @openInfo="$emit('openInfo', item)"
        />
      </a>
      <InfiniteLoading @infinite="loadMore" />
    </template>
    <template v-else-if="isLoading">
      Loading...
    </template>
    <div
      v-else
      class="ape-constructor-search-items-empty"
    >
      Sorry, we couldn't find any traits
    </div>
  </div>
</template>
<script>import { useApiApeTraitsSearch } from "@/api/apes/traits-search";
import { captureError } from "@/helpers/captureError";
import { useApeConstructorStore } from "@/store/ape-constructor";
import { computed, defineComponent, ref, watch } from "vue-demi";
import ApeConstructorTraitCard from "../ApeConstructorTraitCard/ApeConstructorTraitCard.vue";

import InfiniteLoading from "v3-infinite-loading";

export default defineComponent({
  props: { search: String },
  components: { ApeConstructorTraitCard, InfiniteLoading },
  setup(props) {
    const store = useApeConstructorStore()
    const page = ref(1)
    const items = ref([])
    const totalItems = ref(0)
    const isLoading = ref(false)
    const canLoadMore = ref(true)

    const fetchItems = async (isNew) => {
      isLoading.value = true
      try {
        const data = await useApiApeTraitsSearch({ throw: true, toast: { error: true } })
          .exec({
            genderId: store.genderId,
            currentPage: page.value,
            search: props.search,
            bodyTypeId: store.bodyTypeId
          })
        const { traits, pageSize, total } = data
        if (isNew) {
          items.value = traits
        } else {
          let newItems = [...items.value, ...traits]
          newItems = newItems.filter((item, index) => newItems.findIndex(t => t.id == item.id) === index)
          items.value = newItems
          if (!traits.length) {
            canLoadMore.value = false
          }
        }

        totalItems.value = total
      } catch (err) {
        captureError(err)
      }
      isLoading.value = false
    }
    watch(() => props.search, () => {
      page.value = 1
      canLoadMore.value = true
      fetchItems(true)
    })
    fetchItems()
    const loadMore = async () => {
      if (!canLoadMore.value) return
      page.value++
      fetchItems()
    }
    const equipTrait = (trait) => {
      store.toggleTrait(trait)
    }
    const isEquipped = (trait) => {
      return store.equippedTraits.findIndex(item => item.id == trait.id) > -1
    }
    return { fetchItems, loadMore, isEquipped, equipTrait, isLoading, page, items }
  }
})
</script>
<style lang="scss">
.ape-constructor-search {
  &-items {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -9px;
    &-empty {
      text-align: center;
      font-size: 18px;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 1);
      margin: 0 auto;
    }
  }
  &-item {
    width: calc(100% / 5 - 18px);
    margin: 9px;
    text-decoration: none;
  }
  @media screen and (max-width: 1880px) {
    &-item {
      width: calc(100% / 4 - 18px);
    }
  }
  @media screen and (max-width: 1100px) {
    &-item {
      width: calc(100% / 3 - 18px);
    }
  }
  @media screen and (max-width: 760px) {
    &-item {
      width: calc(100% / 2 - 18px);
    }
  }
}
</style>