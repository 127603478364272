<template>
  <div class="ape-constructor-categories">
    <a
      v-for="(category) in items"
      :key="category.id"
      v-tooltip="category.disabled ? 'This category will be added soon' : false"
      v-sound.click
      href="#"
      class="ape-constructor-categories__item"
      :class="{
        disabled: category.disabled
      }"
      @click.prevent="selectItem(category)"
    >
      <div class="ape-constructor-categories__item-image">
        <img
          v-if="!category.disabled"
          :src="category.image"
          width="122"
          height="122"
        >
        <span v-if="category.disabled">Coming soon</span>
      </div>
      <div class="ape-constructor-categories__item-count">{{ category.traitCount }}</div>

      <div class="ape-constructor-categories__item-name">{{ category.name }}</div>
    </a>
  </div>
</template>
<script>import { useApeConstructorStore } from "@/store/ape-constructor";
import { computed, defineComponent } from "vue-demi";

export default defineComponent({
  props: {
    value: Number
  },
  setup(props, { emit }) {
    const store = useApeConstructorStore()
    const items = computed(() => {
      const categories = store.traitCategories.map(item => ({
        ...item,
        traitCount: store.availableTraits.filter(trait => trait.categoryId === item.id).length
      }))
      categories.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
      const disabledCategories = [
        {
          name: "Skin-tones",
          disabled: true
        }
      ]
      return [...categories, ...disabledCategories]
    })
    const selectItem = (category) => {
      if (category.disabled) return;
      emit('update:value', category.id)
    }
    return { selectItem, items }
  }
})
</script>
<style lang="scss">
.ape-constructor-categories {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  box-sizing: border-box;
  &__item {
    margin: 15px;
    border-radius: 5px;
    text-decoration: none;
    color: #fff;
    text-transform: capitalize;
    background-color: #0a0a0a;
    width: calc(100% / 5 - 30px);
    position: relative;
    transition: 0.2s;
    &:hover {
      filter: brightness(0.8);
    }
    &.active {
      background-color: var(--primary);
    }
    &.disabled {
      cursor: default;
      opacity: 0.5;
    }
    &.disabled & {
      &-image {
        padding-bottom: 80%;
        background-color: var(--gray-light);
        max-width: 80%;
        margin: 20% auto;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        span {
          font-size: 12px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          white-space: nowrap;
          text-transform: uppercase;
        }
      }
      &-count {
        display: none;
      }
    }
    &-count {
      position: absolute;
      right: 10px;
      top: 10px;
      background-color: var(--gray-light);
      border: var(--border);
      border-radius: 50%;
      box-sizing: border-box;
      padding: 3px 5px;
      text-align: center;
      min-width: 20px;
      font-size: 12px;
      color: var(--primary);
    }
    &-image {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        max-width: 100%;
        height: auto;
        user-select: none;
      }
    }
    &-name {
      min-height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-style: 20px;
      background-color: #17191c;
      text-transform: uppercase;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
  @media screen and (max-width: 1880px) {
    &__item {
      width: calc(100% / 4 - 30px);
    }
  }

  @media screen and (max-width: 1100px) {
    &__item {
      width: calc(100% / 3 - 30px);
    }
  }
  @media screen and (max-width: 760px) {
    margin: 0 -8px;
    &__item {
      width: calc(100% / 2 - 16px);
      margin: 8px;
    }
  }
}
</style>