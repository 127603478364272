<template>
  <div class="ape-constructor-trait-info">
    <div class="ape-constructor-trait-info-image">
      <img
        :src="item.previewImageSrc"
        alt="trait"
      >
    </div>

    <div class="ape-constructor-trait-info-box">
      <a
        v-sound
        href="#"
        class="ape-constructor-trait-info-close"
        @click.prevent="$emit('close')"
      >
        <IClose width="14" />
      </a>
      <div class="ape-constructor-trait-info-box-inner">
        <div class="ape-constructor-trait-info-name">
          {{ item.name }}
        </div>
        <div
          v-if="isBlocked"
          class="ape-constructor-trait-info-blocked"
        >
          <ICross width="14" />Blocked
        </div>
        <div class="ape-constructor-trait-info-labels">
          <div
            v-if="isOwned"
            class="ape-constructor-trait-info-label ape-constructor-trait-info-label-owned"
          >
            In Wallet
          </div>
          <div
            v-if="!isOwned"
            class="ape-constructor-trait-info-label ape-constructor-trait-info-label-not-owned"
          >
            Not Owned
          </div>
          <div
            v-if="isEquipped"
            class="ape-constructor-trait-info-label ape-constructor-trait-info-label-equipped"
          >
            Equipped
          </div>
        </div>
        <div class="ape-constructor-trait-info-description">
          {{ description }}
        </div>
        <div
          v-if="!isOwned && !isOg"
          class="ape-constructor-trait-info-buy"
        >
          This item can be found in
          <RouterLink
            :to="{ name: 'LootBox' }"
            target="_blank"
          >
            {{ boxType }} box
          </RouterLink>
        </div>
      </div>
    </div>
  </div>
</template>
<script>import { computed, defineComponent } from "vue-demi";
import IClose from "@/assets/icons/close.svg?inline"
import ICross from "@/assets/icons/ape-constructor/ape-constructor-cross.svg?inline"

import { useApeConstructorStore } from "@/store/ape-constructor";
import { useConfirmDialog } from "@vueuse/core";
import { ApeTraitRarity } from "@/helpers/ape-constructor/ape-trait-rarity";
export default defineComponent({
  components: { IClose, ICross },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props, { emit }) {
    const store = useApeConstructorStore()
    const isOwned = computed(() => {
      return store.isOwnTrait(props.item.id)
    })
    const isEquipped = computed(() => {
      return store.equippedTraits.findIndex(trait => props.item.id === trait.id) > -1
    })
    const isBlocked = computed(() => {
      return store.isBlockedTrait(props.item.id)
    })
    const boxType = computed(() => {
      return props.item.rarity.charAt(0).toUpperCase() + props.item.rarity.slice(1)
    })
    const isOg = computed(() => props.item.rarity === ApeTraitRarity.og)
    const description = computed(() => {
      if (isBlocked.value) {
        return `This trait is blocked by other traits: ${(store.getBlockingTraits(props.item.id)).map(item => item.name).join(', ')} Change or de-equip these traits from your Ape in order to equip ${props.item.name}.`
      }
      if (isOwned.value && !isEquipped.value) {
        return 'This trait can be found in your wallet, and you have the option to either equip it or sell it on the marketplace.'
      }
      if (isOwned.value && isEquipped.value) {
        return 'An exciting addition to your arsenal, this remarkable trait has made its way into your wallet and is now proudly worn by your mighty ape!'
      }
      if (!isOwned.value && isEquipped.value) {
        if (isOg.value) {
          return `This trait is equipped on your Ape, it looks awesome ! Sadly, it's not yet in your possession, but all hope is not lost! `
        } else {
          return `This trait is equipped on your Ape, it looks awesome ! Sadly, it's not yet in your possession, but all hope is not lost! Crack open a ${boxType.value} loot box for a chance to claim it as your own.`
        }
      }
      if (!isOwned.value && !isEquipped.value) {
        if (isOg.value) {
          return `This trait is missing from your collection.`
        }
        return `This trait is missing from your collection. Take a chance and open a ${boxType.value} loot box, who knows, you may just be the lucky one to add it to your wallet!`
      }
    })

    return { isOwned, isOg, boxType, isBlocked, isEquipped, description }
  },
})
</script>
<style lang="scss">
.ape-constructor-trait-info {
  padding-top: 60px;
  max-width: 800px;

  padding-left: 50px;
  box-sizing: border-box;
  margin-left: auto;
  position: sticky;
  top: 50px;
  padding-bottom: 30px;
  @media screen and (max-width: 1300px) {
    padding-left: 0;
  }
  &-image {
    margin-bottom: 30px;
    img {
      max-width: 100%;
      height: auto;
      width: 70%;
      display: block;
      margin: 0 auto;
    }
  }
  &-close {
    color: #757575;
    border: 1px solid #757575;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 10px;
    &::before {
      content: "";
      background-color: rgba(250, 250, 250, 0.1);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 28px;
      height: 28px;
      border-radius: 50%;
    }
  }
  &-box {
    background-color: #121212;
    padding: 16px 26px;
    &-inner {
      border: 1px solid var(--ape-constructor-gray);
      padding: 26px;
      padding-bottom: 32px;
      position: relative;
      &::before,
      &::after {
        content: "";
        position: absolute;
        bottom: 22px;
        left: 26px;
        right: 26px;
        height: 1px;
        background-color: #2c2828;
      }
      &::after {
        bottom: 14px;
      }
    }
  }
  &-name {
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 12px;
  }
  &-blocked {
    display: flex;
    color: var(--ape-constructor-accent);
    font-size: 16px;
    margin-bottom: 16px;
    svg {
      margin-right: 5px;
    }
  }
  &-labels {
    display: flex;
    align-items: stretch;
    margin-bottom: 16px;
  }
  &-label {
    margin-right: 16px;
    padding: 9px 11px;
    font-size: 12px;
    font-style: italic;
    max-width: 210px;
    flex: 1;
    &:last-child {
      margin-right: 0;
    }
    &-owned {
      background-color: var(--ape-constructor-gray);
    }
    &-not-owned {
      background-color: var(--ape-constructor-accent);
    }
    &-equipped {
      background-color: var(--ape-constructor-primary);
    }
  }
  &-description {
    font-family: var(--font-oxanium);
    font-weight: 500;
  }
  &-buy {
    margin-top: 5px;
    a {
      color: var(--primary);
    }
  }
  &-close {
  }
  @media screen and (max-width: 950px) {
    position: fixed;
    z-index: 30;
    top: 80px;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 30px;
    background-color: #121212;
    font-size: 14px;
    max-width: none;
    overflow: auto;
    &-image {
      img {
        max-width: 400px;
      }
    }
    &-close {
      position: absolute;
      top: 20px;
      left: 20px;
    }
    &-box {
      padding: 10px;
      &-inner {
        padding: 15px;
        &::before,
        &::after {
          display: none;
        }
      }
    }
  }
}
</style>