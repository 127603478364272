import { useConfirmModal } from "@/composables/useConfirmModal";
import { useToastTransaction } from "@/composables/useToast/useToastTransaction";
import { captureError } from "@/helpers/captureError";
import { useWeb3Store } from "@/store/web3";
import { useApeTraitsContract, useEquipOffChainContract } from "@/web3/contracts";

export const approveApeTraitsOffChain = async () => {
    try {
      const wallet = useWeb3Store().walletAddress;
      const approveAddress = useEquipOffChainContract().options.address;
      const isApproved = await useApeTraitsContract()
        .methods.isApprovedForAll(wallet, approveAddress)
        .call();
      if (isApproved) return true;
      const confirmed = await useConfirmModal({
        title: "Contract needs your approval",
        text: `The contract requires your approval to use your Ape traits tokens.
        <br><br>After you approve the contract, minting ape will continue.`,
      });
      if (!confirmed) return false;
      const approveForAllResponse = await useToastTransaction(
        useApeTraitsContract(true).methods.setApprovalForAll(
          approveAddress,
          true
        ),
        {
          text: "Approving Ape Traits Contract",
          successText: "You have successfully approved the Ape Traits Contract",
        }
      );
      return approveForAllResponse;
    } catch (err) {
      captureError(err);
    }
  };