<template>
  <div
    id="breadcrumbs"
    class="ape-constructor-breadcrumbs-wrapper"
  >
    <div class="ape-constructor-breadcrumbs">
      <div
        v-sound.click
        class="ape-constructor-breadcrumbs-item"
      >
        <component
          :is="name ? 'a' : 'div'"
          href="javscript:void(0)"
          @click.prevent="$emit('back')"
        >
          <IGrid2
            width="14"
            class="ape-constructor-breadcrumbs-item-icon"
          />Category Menu
        </component>
        <span v-if="name">
          <IArrowRight width="8" />
        </span>
      </div>
      <div
        v-if="name"
        class="ape-constructor-breadcrumbs-item"
      >
        <div>{{ name }}</div>
      </div>
    </div>
    <div
      v-if="name"
      class="ape-constructor-breadcrumbs-hint"
    >
      <IHint width="14" />Click on hint icon to see more about trait
    </div>
  </div>
</template>
<script>import { defineComponent } from "vue-demi";
import IArrowRight from "@/assets/icons/ape-constructor/ape-constructor-arrow-right.svg?inline"
import IHint from "@/assets/icons/ape-constructor/ape-constructor-hint.svg?inline"
import IGrid2 from "@/assets/icons/grid-2.svg?inline";

export default defineComponent({
  components: { IArrowRight, IGrid2, IHint },
  props: {
    name: String
  }
})
</script>
<style lang="scss">
.ape-constructor-breadcrumbs {
  display: flex;
  align-items: center;
  &-wrapper {
    display: flex;
    justify-content: space-between;
  }
  &-hint {
    display: flex;
    align-items: center;
    color: var(--ape-constructor-gray);
    font-weight: bold;
    margin-left: 10px;
    svg {
      margin-right: 8px;
      flex-shrink: 0;
      max-height: 20px;
      min-height: 0;
    }
  }
  &-item {
    color: #818181;
    font-weight: bold;
    font-size: 18px;

    text-transform: uppercase;
    display: flex;
    align-items: center;
    white-space: nowrap;
    span {
      margin: 0 10px;
      font-size: 0;
    }
    &-icon {
      margin-right: 5px;
    }
    &:last-child {
      color: #fff;
    }
    a {
      text-decoration: none;
      color: inherit;
      transition: 0.2s;
      &:hover {
        color: #fff;
      }
    }
  }
  @media screen and (max-width: 1170px) {
    &-wrapper {
      flex-direction: column;
    }
    &-hint {
      margin-top: 8px;
      margin-left: 0;
      svg {
        flex-shrink: 0;
      }
    }
  }
  @media screen and (max-width: 760px) {
    &-hint {
      font-size: 12px;
      svg {
        margin-right: 5px;
      }
    }
  }
}
</style>
