import { useApesStorageApi } from "@/api/useApesStorageApi";
import { ModalName } from "@/components/Modal/modal-name";
import { useConfirmModal } from "@/composables/useConfirmModal";
import { useContract } from "@/composables/useContract";
import { useModal } from "@/composables/useModal";
import { useToast } from "@/composables/useToast";
import { useToastTransaction } from "@/composables/useToast/useToastTransaction";
import { Config } from "@/config";
import { getApeChangeCode } from "@/helpers/ape-constructor/ape-chage-code";
import { getApeTraitsChanges } from "@/helpers/ape-constructor/ape-traits-changes";
import { captureError } from "@/helpers/captureError";
import { useApeConstructorStore } from "@/store/ape-constructor";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { useWeb3Store } from "@/store/web3";
import { useEquipOffChainContract } from "@/web3/contracts";
import { approveApeTraits } from "./approveApeTraits";
import { approveApeTraitsOffChain } from "./approveApeTraitsOffChain";




export const equipApeOffChain = async (image) => {
  try {
    const apeConstructorStore = useApeConstructorStore();
    const walletTraits = apeConstructorStore.walletTraits;
    const wallet = useWeb3Store().walletAddress;
    const tokenId = apeConstructorStore.ape.id;
    const { traitsAllIn, traitsAllOut } = getApeTraitsChanges();
    // on-chain IN
    const traitsIn = traitsAllIn
      .filter((trait) => !walletTraits.find((item) => item.id === trait.id).offChain)
      .map((trait) => trait.id);
    //   off-chain IN
    const traitsOffChainIn = traitsAllIn
      .filter((trait) => walletTraits.find((item) => item.id === trait.id).offChain)
      .map((trait) => trait.id);
    const traitsOffChainOut = traitsAllOut.map((trait) => trait.id);
    console.log(
      "out",
      traitsOffChainOut,
      "in",
      traitsIn,
      "offchain in",
      traitsOffChainIn
    );
    const changeCode = getApeChangeCode();
    console.log("code", changeCode);
    if (!traitsIn.length && !traitsOffChainOut.length && !traitsOffChainIn.length) {
      useToast().error({ title: "You haven't changed your Ape" });
      return;
    }    
    if (traitsIn.length && !(await approveApeTraitsOffChain())) return;
    const data = await useApesStorageApi({
      throw: true,
      toast: { error: true },
    }).exec({
      url: "/constructor/offchain-equip",
      method: "POST",
      data: {
        wallet,
        apeId: tokenId,
        traitsIn,
        traitsOffChainIn,
        traitsOffChainOut,
        changeCode,
        network: Config.network.name,
      },
    });

    if (!data) throw new Error("Cound't sign transaction", { cause: data });
    const { signature, timeOut } = data;
    useModal().open({
      name: ModalName.ApeMintModal,
      props: { image, type: "minting" },
    });
    await useToastTransaction(
      useEquipOffChainContract(true).methods.equipWithOffChainRemoval(
        tokenId,
        traitsIn,
        traitsOffChainIn,
        traitsOffChainOut,
        timeOut,
        changeCode,
        signature
      ),
      {
        text: "Equipping Ape",
      }
    );
    useModal().close();
    useModal().open({
      name: ModalName.ApeMintModal,
      props: { image, type: "done" },
    });
    useWalletCollectionStore().fetchApes();

    return true;
  } catch (err) {
    captureError(err);
    useModal().close();
  }
};
