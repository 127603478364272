<template>
  <div class="ape-constructor-tabs">
    <div class="ape-constructor-tabs__item">
      <ApeConstructorBreadcrumbs
        :class="{ active: tab === 'traits' }"
        class="ape-constructor-tabs__categories"
        :name="activeCategory"
        @click="selectTab('traits')"
        @back="$emit('back')"
      />
    </div>
    <div
      v-if="!activeCategory"
      class="ape-constructor-tabs__item"
    >
      <a
        v-sound.click
        href="#"
        :class="{ active: tab === 'my-traits' }"
        class="ape-constructor-tabs__my-traits"
        @click.prevent="selectTab('my-traits')"
      >
        <IWallet width="18" />My Traits
      </a>
    </div>
  </div>
</template>
<script setup>
import ApeConstructorBreadcrumbs from "../ApeConstructorBreadcrumbs.vue";
import IWallet from "@/assets/icons/ape-constructor/ape-constructor-wallet.svg?inline";

const props = defineProps({ activeCategory: String, tab: String })
const emit = defineEmits(['update:tab'])
const selectTab = (value) => {
    emit('update:tab', value)
}
</script>
<style lang="scss">
.ape-constructor-tabs {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    &__item {
    }
    &__categories {
        opacity: 0.6;
        cursor: pointer;
        &.active {
            opacity: 1;
        }
        transition: 0.2s;

        &:hover {
            opacity: 1;
        }
    }
    &__my-traits {
        color: #fff;
        text-decoration: none;
        font-size: 18px;
        margin-left: 8px;
        padding-left: 10px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        position: relative;
        color: rgba(255, 255, 255, 0.6);
        svg {
            margin-right: 5px;
        }
        transition: 0.2s;
        &:hover {
            color: rgba(255, 255, 255, 1);
        }
        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 3px;
            bottom: 3px;
            width: 2px;
            background-color: #fff;
            opacity: 0.5;
        }
        &.active {
            color: rgba(255, 255, 255, 1);
        }
    }
    @media screen and (max-width: 400px) {
        flex-direction: column;
        align-items: flex-start;
        &__my-traits {
            padding-left: 0;
            margin-left: 0;
            margin-top: 8px;
            &::before {
                display: none;
            }
        }
    }
}
</style>