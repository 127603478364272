<template>
  <div
    id="equipped-traits"
    class="ape-constructor-equipped-traits"
    :class="{ open: isOpen }"
  >
    <a
      href="#"
      class="ape-constructor-equipped-traits-header"
      @click.prevent="toggleOpen()"
    >
      Equipped
      <IArrowRight width="8" />
    </a>

    <div
      v-if="isOpen"
      class="ape-constructor-equipped-traits-items"
    >
      <a
        v-sound.click
        href="javascript:void(0)"
        class="ape-constructor-equipped-traits-items-arrow-left ape-constructor-arrow ape-constructor-arrow-left"
      >
        <IArrowRight width="14" />
      </a>
      <a
        v-sound.click
        href="javascript:void(0)"
        class="ape-constructor-equipped-traits-items-arrow-right ape-constructor-arrow ape-constructor-arrow-right"
      >
        <IArrowRight width="14" />
      </a>
      <Swiper
        v-if="showSlider"
        :modules="modules"
        :navigation="sliderNavigation"
        :slides-per-view="2"
        :space-between="15"
        :breakpoints="breakpoints"
      >
        <SwiperSlide
          v-for="item in items"
          :key="item.id"
        >
          <ApeConstructorEquippedTrait
            :item="item"
            @change="onChangeTrait(item)"
            @deEquip="onDeEquip(item)"
          />
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>
<script>
import { useApeConstructorStore } from "@/store/ape-constructor";
import { useToggle } from "@vueuse/shared";
import { computed, defineComponent, onMounted, ref } from "vue-demi";
import IArrowRight from "@/assets/icons/ape-constructor/ape-constructor-arrow-right.svg?inline"
import ApeConstructorEquippedTrait from "./ApeConstructorEquippedTrait.vue";
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import { Navigation, } from 'swiper';
import 'swiper/swiper-bundle.css';
export default defineComponent({
  components: {
    IArrowRight, ApeConstructorEquippedTrait, Swiper,
    SwiperSlide
  },
  props: {
    activeCategoryId: Number
  },
  setup(props, { emit }) {
    const [isOpen, toggleOpen] = useToggle(true)
    const store = useApeConstructorStore()
    const items = computed(() => [...store.equippedTraits].sort((a, b) => a.categoryId - b.categoryId))
    const modules = [Navigation]
    const onChangeTrait = (trait) => {
      emit('update:activeCategoryId', trait.categoryId)
    }
    const onDeEquip = (trait) => {
      store.deEquipTrait(trait)
    }
    const breakpoints = {
      1880: {
        slidesPerView: 5
      },
      1100: {
        slidesPerView: 4
      },
      950: {
        slidesPerView: 3
      },
      768: {
        slidesPerView: 4
      },
      576: {
        slidesPerView: 3
      }
    }
    const showSlider = ref(false)
    onMounted(() => {
      showSlider.value = true
    })
    const sliderNavigation = computed(() => ({
      nextEl: '.ape-constructor-equipped-traits-items-arrow-right',
      prevEl: '.ape-constructor-equipped-traits-items-arrow-left'
    }))
    return {
      items, isOpen, showSlider, breakpoints, sliderNavigation, toggleOpen, modules, onChangeTrait, onDeEquip
    }
  }
})
</script>

<style lang="scss">
.ape-constructor-equipped-traits {
  margin-bottom: 24px;
  &-header {
    display: inline-flex;
    align-items: center;
    color: var(--ape-constructor-gray);
    text-decoration: none;
    transition: 0.3s;

    svg {
      margin-left: 8px;
      transition: transform 0.3s;
    }
    &:hover {
      color: #fff;
    }
  }
  &-items {
    padding-top: 16px;
    position: relative;
    &-arrow {
      &-left {
        left: -14px;
      }
      &-right {
        right: -14px;
      }
    }
  }
  &.open & {
    &-header {
      svg {
        transform: rotate(90deg);
      }
    }
  }
}
</style>