<template>
  <div class="ape-constructor-content-hint">
    <IUnlock width="40" />
    <div>
      <span>Every Day is Mint Day!</span> New traits are being unlocked daily.
      Come back often to see the latest!
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue"
import IUnlock from '@/assets/icons/unlock.svg?inline'
export default defineComponent({
    components: { IUnlock },
    setup() {
        return {}
    }
})
</script>
<style lang="scss">
.ape-constructor-content-hint {
    margin-bottom: 50px;
    display: flex;
    align-items: flex-start;
    font-weight: normal;
    font-size: 14px;
    background-color: rgba(162, 71, 237, 0.507);
    border: 1px solid #a347ed;
    padding: 8px;
    border-radius: 5px;
    font-size: 16px;
    svg {
        margin-right: 5px;
        flex-shrink: 0;
        margin-top: 2px;
    }
    span {
        color: rgba(255, 255, 255, 1);
        display: block;
        font-weight: bold;
        font-size: 18px;
    }
    @media screen and (max-width: 768px) {
        font-size: 14px;
        span {
            font-size: 16px;
        }
    }
}
</style>