import { useApeConstructorStore } from "@/store/ape-constructor";

export const getApeChangeCode = () => {
  const store = useApeConstructorStore();
  let code = "";
  store.equippedTraits.forEach((trait) => {
    const category = store.traitCategories.find(
      (cat) => cat.id === trait.categoryId
    );
    if (!category) return;
    code += `${category.sym}${trait.id}`;
  });
  code += `G${store.genderId}`;
  code += `FS${store.bodyTypeId}`;
  return code;
};
