<template>
    <div class="ape-constructor-menu">
        <a
            v-for="(item, idx) in items"
            :key="idx"
            href="#"
            class="ape-constructor-menu-item"
            @click.prevent="goTo(item.value)"
        >{{ item.name }}</a>
        <RouterLink

            :to="{ name: 'TraitShop' }"
            class="ape-constructor-menu-item"
            @click.prevent="goTo()"
        >
            <IShop width="14" />The Shop
        </RouterLink>
    </div>
</template>
<script>
import { defineComponent } from "vue"
import IShop from "@/assets/icons/shop.svg?inline"
export default defineComponent({
    components: { IShop },
    setup() {
        const items = [
            {
                name: "Current Ape",
                value: "currentApe"
            },
            {
                name: "Equipped traits",
                value: "equippedTraits"
            },
            {
                name: "Go To Categories",
                value: "categories"
            }
        ]
        const scrollTo = (element) => {
            const headerOffset = 85;
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
        const goTo = value => {

            if (value === 'currentApe') {
                scrollTo(document.querySelector('#current-ape'))

            }
            if (value === 'equippedTraits') {
                scrollTo(document.querySelector('#equipped-traits'))
            }
            if (value === 'categories') {
                scrollTo(document.querySelector('#breadcrumbs'))


            }
        }
        return { items, goTo }
    }
})
</script>
<style lang="scss">
.ape-constructor-menu {
    margin-bottom: 20px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &-item {
        margin: 0 5px;
        color: rgba(255, 255, 255, 0.5);
        position: relative;
        text-decoration: none;
        transition: 0.2s;
        white-space: nowrap;
        &:hover {
            color: #fff;
        }

        &::after {
            content: "";
            height: 1px;
            width: 100%;
            position: absolute;
            bottom: -2px;
            left: 0;
            right: 0;
            background-color: currentColor;
        }
        svg {
            margin-right: 5px;
        }
    }
    @media screen and (max-width: 576px) {
        flex-direction: column;
        &-item {
            font-size: 14px;
            margin: 0;
            margin-bottom: 15px;
        }
    }
}
</style>
