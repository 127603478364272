<template>
  <div class="ape-constructor-preview-wrapper" :class="{ 'no-ape-preview': isNoApePreview }">
    <div ref="previewEl" class="ape-constructor-preview">
      <div
        ref="previewCanvasWrapper"
        class="ape-constructor-preview-canvas-wrapper"
        :class="{ sticked: isCanvasSticked }"
      >
        <div
          ref="previewCanvas"
          v-sound.click.excited
          class="ape-constructor-preview-canvas"
          @click.prevent="zoomImage"
        >
          <div
            class="ape-constructor-preview-label-disclaimer"
            v-if="isPreview"
            v-tooltip="{ content: `You are seeing Preview Banner because you don't own the Traits you equipped to the Ape`, placement: 'bottom' }"
          />
        </div>
        <div v-if="isLoading" class="ape-constructor-preview-loading">
          <img src="@/assets/img/spinner-clear.svg" class="spinner" width="60" />
        </div>
        <IZoom
          v-if="!isLoading"
          width="20"
          class="ape-constructor-preview-zoom"
          @click.stop.prevent="zoomImage"
        />
      </div>
      <div v-if="isCanvasSticked" class="ape-constructor-preview-placeholder" />
      <a
        v-sound.click.excited
        href="#"
        class="ape-constructor-preview-share"
        :class="{ disabled: isSharing }"
        @click.prevent="shareTwitter"
      >
        Share
        <ITwitter width="16" />
      </a>
    </div>

    <div class="ape-constructor-preview-items">
      <div class="ape-constructor-preview-items-row">
        <div
          class="ape-constructor-preview-item ape-constructor-preview-item-ape-id"
          v-if="!isNoApePreview"
        >
          <div class="ape-constructor-preview-item-name">BAP ID</div>
          <div class="ape-constructor-preview-item-value">#{{ apeOriginalId }}</div>
        </div>
        <div class="ape-constructor-preview-item ape-constructor-preview-item-rank">
          <div
            v-tooltip="{ html: true, content: 'Projected ranks are estimated. <br> Subject to change any second and may change upon minting.' }"
            class="ape-constructor-preview-item-name"
          >
            RANK
            <IInfo width="10" />
          </div>
          <div class="ape-constructor-preview-item-value">
            <span v-if="rank">{{ rank }}</span>
            <a
              v-if="!rank"
              class="ape-constructor-preview-item-rank-button"
              href="javascript:void(0)"
              @click.prevent="getRank"
              v-sound.click
            >Get Rank</a>
          </div>
        </div>

        <div class="ape-constructor-preview-item" v-if="!isNoApePreview">
          <div class="ape-constructor-preview-item-name">TRAIT count</div>
          <div class="ape-constructor-preview-item-value">{{ traitCount }}</div>
        </div>
      </div>
      <div class="ape-constructor-preview-items-row">
        <div class="ape-constructor-preview-item" v-if="!isNoApePreview">
          <div class="ape-constructor-preview-item-name">Body type</div>
          <div class="ape-constructor-preview-item-value">{{ bodyTypeName }}</div>
        </div>
        <div class="ape-constructor-preview-item" v-if="!isNoApePreview">
          <div class="ape-constructor-preview-item-name">GUILD</div>
          <div class="ape-constructor-preview-item-value">{{ guild }}</div>
        </div>
        <div class="ape-constructor-preview-item">
          <div class="ape-constructor-preview-item-name">GENDER</div>
          <div class="ape-constructor-preview-item-value">{{ gender }}</div>
        </div>
      </div>
    </div>
    <div class="ape-constructor-preview-action">
      <div v-if="isMintBlocked && !isNoApePreview" class="ape-constructor-preview-analyze">
        <div class="ape-constructor-preview-analyze-title">Mint blocked!</div>
        <div
          class="ape-constructor-preview-analyze-text"
        >Some equipped traits are not available in the wallet</div>
        <a
          v-sound.click
          href="javascript:void(0)"
          class="ape-constructor-preview-button ape-constructor-preview-analyze-button"
          @click.prevent="analyze"
        >Analyze what’s missing</a>
      </div>
      <div class="ape-constructor-preview-demo-banner" v-if="isNoApePreview">
        
        <div class="ape-constructor-preview-demo-banner-title">
          This is a Demo Ape
        </div>

      </div>
      <a
        v-if="!isNoApePreview && !isMintBlocked"
        v-sound.click.excited
        href="javascript:void(0)"
        class="ape-constructor-preview-button"
        :class="{ disabled: isMintDisabled }"
        @click.prevent="runMint"
      >MINT</a>
      <a
        v-if="isNoApePreview"
        v-sound.click.excited
        target="_blank"
        class="ape-constructor-preview-button ape-constructor-preview-button-opensea"
        :href="Config.OSUrl.apes"
      >
        <IOpenSea width="36" />Shop for an Ape
      </a>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref, watch, toRef, computed, onUnmounted } from "vue";
import * as PIXI from 'pixi.js';
import { useApeConstructorStore } from "@/store/ape-constructor";
import { watchOnce } from "@vueuse/shared";
import IPreviewShape from "@/assets/icons/ape-constructor/ape-constructor-preview-shape.svg?inline"
import ITwitter from "@/assets/icons/twitter.svg?inline"
import IInfo from "@/assets/img/info.svg?inline"
import IZoom from "@/assets/icons/zoom.svg?inline"
import IOpenSea from "@/assets/icons/opensea.svg?inline"
import { captureError } from "@/helpers/captureError";
import { useModal } from "@/composables/useModal";
import { ModalName } from "@/components/Modal/modal-name";
import { ApeConstructorCategory } from "@/helpers/ape-constructor/ape-constructor-categories";
import { equipApe } from "@/functions/equipApe/equipApe";
import { useApesStorageApi } from "@/api/useApesStorageApi";
import { shareApeTwitter } from "@/helpers/ape-constructor/ape-twitter-share"
import { equipApeOffChain } from "@/functions/equipApe/equipApeOffChain";
import { Config } from "@/config";


const loadImage = async (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.crossOrigin = 'anonymous'
    img.onload = () => resolve(PIXI.Texture.from(img))
    img.onerror = reject
    img.src = src
  })
}
export default defineComponent({
  components: { IPreviewShape, IOpenSea, IInfo, ITwitter, IZoom },
  setup() {

    const store = useApeConstructorStore()
    const previewCanvas = ref(null)
    const cWidth = 800
    // const cWidth = 2048
    const cHeight = 800
    // const cHeight = 2048
    const app = new PIXI.Application({ height: cHeight, width: cWidth, backgroundAlpha: 0 });
    app.stage.sortableChildren = true;
    app.renderer.plugins.interaction.destroy()
    const isRendering = ref(false)
    const isLoading = computed(() => store.isLoading || isRendering.value)
    const isMinting = ref(false)
    const init = async () => {
      previewCanvas.value.appendChild(app.view)
    }

    onMounted(() => {
      init()
      renderTraits(1)
    })

    const renderId = ref(1)
    let toDestroy = []
    const renderTraits = async (rId) => {
      let currentTextures = []
      isRendering.value = true
      clearTraits()

      try {

        const buildMask = async (items) => {
          try {
            const masks = new PIXI.Container()
            const defaultSprite = new PIXI.Graphics();
            defaultSprite.beginFill(0xFFFFFF);
            defaultSprite.drawRect(0, 0, cWidth, cHeight);
            masks.addChild(defaultSprite)
            const resolvers = items.map(async (m, index) => {
              const image = m.imageSrc.replace("original", "w=800")
              const texture = await loadImage(image)
              currentTextures.push(texture)
              const mask = new PIXI.Sprite(texture);
              mask.blendMode = PIXI.BLEND_MODES.MULTIPLY
              masks.addChild(mask)
            })

            await Promise.all(resolvers)

            const finalMask = masks.children.length ? new PIXI.Sprite(app.renderer.generateTexture(masks)) : undefined
            return finalMask
          } catch (err) {
            console.error('[Body Mask]', err)
          }
        }
        const getBodyMask = () => {

          return buildMask(store.helperTraitMasks.bodyMasks)
        }
        const getDefaultHairMask = () => {

          return buildMask(store.helperTraitMasks.defaultHairMasks)
        }
        const [bodyMask, defaultHairMask] = await Promise.all([getBodyMask(), getDefaultHairMask()])
        const bodyTrait = store.equippedTraits.find(trait => trait.categoryId === ApeConstructorCategory.Body)
        const bodyImages = bodyTrait && store.traitsImages[store.getTraitImageCacheKey(bodyTrait)]
        const resolvers = store.equippedTraitsImages.map(async images => {
          const isBody = bodyImages === images
          // const isHelper = store.helperTraitImages === images
          const resolvers = images.map(async (image) => {

            const texture = await loadImage(image.imageSrc.replace("original", "w=800"))
            currentTextures.push(texture)

            const sprite = new PIXI.Sprite(texture);
            sprite.zIndex = image.zIndex;

            // first is lowest zIndex
            const isFirst = images.findIndex(img => img.zIndex < image.zIndex) < 0
            // applying masks only to first body image
            if (isBody && isFirst && bodyMask) {
              sprite.mask = bodyMask
            }
            if (image.imageName === 'default_hair.png' && defaultHairMask) {
              sprite.mask = defaultHairMask
            }
            return sprite
          })
          const sprites = await Promise.all(resolvers)
          return sprites
        })
        let sprites = await Promise.all(resolvers)
        sprites = sprites.reduce((arr, item) => arr.concat(item), [])
        // To avoid race condition
        if (rId === renderId.value && app.stage) {
          sprites.forEach(sprite => app.stage.addChild(sprite))
        }
        if (bodyMask) {
          toDestroy.push(bodyMask)
        }
        if (defaultHairMask) {
          toDestroy.push(defaultHairMask)
        }
        toDestroy = [...toDestroy, ...sprites, ...currentTextures]

      } catch (err) {
        captureError(err)
      }
      setTimeout(() => {
        if (rId === renderId.value) {
          isRendering.value = false
        }
      }, 100)
    }
    const clearTraits = () => {
      app.stage.removeChildren()
      toDestroy.forEach(texture => {
        texture.destroy(true)
      })
      toDestroy = []
    }

    const onRender = async () => {
      // for (let i = 0; i < 100; i++) {
      renderId.value++
      await renderTraits(renderId.value)
      // }
    }
    store.ee.on('render', onRender)
    const isMintDisabled = computed(() => {
      if (isMinting.value) return true

      const traitsAllIn = store.equippedTraits.filter((trait) => {
        const { name, categoryName } = trait;
        const existed =
          store.ape.attributes.findIndex(
            (attr) => attr.trait_type.toLowerCase() === categoryName.toLowerCase() && attr.value.toLowerCase() === name.toLowerCase()
          ) > -1;
        if (existed) return false;
        return true;
      });
      const traitsAllOut = store.defaultTraits.filter((trait) => {
        // exclude gender,body type etc
        if (!trait.id) return false;
        const { trait_type, value } = trait;
        const exists =
          store.equippedTraits.findIndex(
            (attr) => attr.name.toLowerCase() === value.toLowerCase() && attr.categoryName.toLowerCase() === trait_type.toLowerCase()
          ) > -1;
        return !exists;
      });
      return traitsAllOut.length <= 0 && traitsAllIn.length <= 0
    })
    const isMintBlocked = computed(() => {
      const notOwnTraits = store.equippedTraits.filter(trait => !store.isOwnTrait(trait.id))
      return notOwnTraits.length > 0
    })
    const analyze = () => {
      useModal().open({
        name: ModalName.ApeConstructorAnalyzeModal,
      })
    }
    const traitCount = computed(() => store.equippedTraits.length)
    const bodyTypeName = computed(() => store.bodyTypeId === 1 ? 'Fur' : 'Skin')
    const guild = computed(() => store.guild)
    const gender = computed(() => store.genderId === 1 ? 'Male' : "Female")
    const apeId = computed(() => store.ape.id)
    const rank = ref(null)
    watch(() => store.equippedTraits, () => {
      rank.value = null
    }, { deep: true })
    const getRank = async () => {
      const data = await useApesStorageApi({ toast: { error: true } }).exec({
        url: "/constructor/dynamic-rank",
        params: {
          currentTraitIds: store.equippedTraits.map(trait => trait.id),
          network: Config.network.name
        }
      })
      if (!data) return
      rank.value = data
    }
    const apeOriginalId = computed(() => {
      return store.originalId
    })
    const apeTokenId = computed(() => {
      return store.tokenId
    })
    const isSharing = ref(false)
    const shareTwitter = async () => {
      isSharing.value = true
      await shareApeTwitter()
      isSharing.value = false
    }
    const runMint = async () => {
      isMinting.value = true

      if (isLoading.value) {
        await new Promise(resolve => {
          watchOnce(isLoading, () => {
            if (!isLoading.value) resolve()
          })
        })
      }
      const image = await app.renderer.extract.base64(app.stage)
      // await equipApe(image)
      await equipApeOffChain(image)
      isMinting.value = false
    }
    const zoomImage = async () => {
      if (isLoading.value) {
        await new Promise(resolve => {
          watchOnce(isLoading, () => {
            if (!isLoading.value) resolve()
          })
        })
      }
      const image = await app.renderer.extract.base64(app.stage)
      useModal().open({ name: ModalName.ImageModal, props: { image } })
    }
    const isCanvasSticked = ref(false)
    const canCanvasStick = ref(false)
    const checkResize = () => {
      if (window.innerWidth < 950) {
        canCanvasStick.value = true
      } else {
        canCanvasStick.value = false

      }
    }

    const previewEl = ref()
    const previewCanvasWrapper = ref()
    const checkScroll = (e) => {
      // previewCanvas
      const elementPosition = previewEl.value.getBoundingClientRect()
      const offset = 80
      // const total =elementPosition.top + window.pageYOffset ;
      const total = elementPosition.top - offset + elementPosition.height;
      if (total < 0 && canCanvasStick.value) {
        isCanvasSticked.value = true
      } else {
        isCanvasSticked.value = false

      }
    }
    const isPreview = computed(() => store.equippedTraits.findIndex(trait => !store.isOwnTrait(trait.id)) > -1)
    const isNoApePreview = computed(() => apeTokenId.value === 1 || apeTokenId.value === 2)
    onMounted(() => {
      window.addEventListener('resize', checkResize)
      window.addEventListener('scroll', checkScroll)
      checkResize()
      checkScroll()
    })
    onUnmounted(() => {
      window.removeEventListener('resize', checkResize)
      window.removeEventListener('scroll', checkScroll)
      store.ee.off('render', onRender)
      clearTraits()
      app.stage.destroy(true)
      app.destroy(true)
    })
    return {
      previewCanvas, isNoApePreview, Config, previewEl, isPreview, zoomImage, rank, getRank, isSharing, renderId, isCanvasSticked, apeOriginalId, apeTokenId, apeId, runMint, shareTwitter, isMintDisabled, isLoading, isMintBlocked, analyze, gender, traitCount, guild, bodyTypeName
    }
  }
})
</script>
<style lang="scss">
.ape-constructor-preview {
  position: relative;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  min-height: 0;
  &::before {
    content: "";
    width: 100%;
    position: absolute;
    height: 2px;
    background-color: var(--ape-constructor-gray);
    top: -8px;
    left: 0;
    right: 0;
  }
  &::after {
    content: "";
    width: 90%;
    position: absolute;
    height: 2px;
    background-color: var(--ape-constructor-gray);
    top: -14px;
    left: 50%;
    transform: translateX(-50%);
  }
  &-wrapper {
    background: linear-gradient(180deg, #17111f 0%, #22080a 100%);
    padding: 30px;
    padding-top: 32px;
    position: sticky;
    width: auto;
    top: 80px;
    box-sizing: border-box;
    z-index: 20;
    max-width: 100%;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    &.no-ape-preview {
      .ape-constructor-preview-items {
        display: flex;
        > div {
          flex: 1;
          &:first-child {
            margin-right: 5px;
          }
        }
      }
    }
  }

  &-shape {
    margin-top: -18%;
    position: relative;
    z-index: 5;
    width: 100%;
  }
  &-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(20px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    img {
      max-width: 70%;
    }
  }
  &-zoom {
    position: absolute;
    right: 10px;
    bottom: 10px;
    background: var(--ape-constructor-gray);
    border-radius: 5px;
    cursor: pointer;
    padding: 5px;
    transition: 0.2s;
    opacity: 0;
    &:hover {
      filter: brightness(0.8);
    }
  }
  &-label-disclaimer {
    position: absolute;
    right: 0;
    top: 0;
    width: 25%;
    height: 25%;
    background-color: transparent;
    z-index: 10;
  }
  &-demo-banner {
    text-align: center;
    font-size: 18px;
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid var(--red);
    border-radius: 5px;
    text-transform: uppercase;
    color: var(--red);

  }
  &-canvas {
    font-size: 0;
    display: flex;
    flex-direction: column;
    min-height: 0;
    align-items: center;
    background: var(--ape-constructor-bg);
    border: 2px solid var(--ape-constructor-gray);
    cursor: pointer;
    position: relative;
    canvas {
      max-width: 100%;
      max-height: 100%;
    }
    &-wrapper {
      max-width: 100%;
      width: auto;
      position: relative;
      z-index: 1;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      flex-direction: column;
      min-height: 0;
      &:hover {
        .ape-constructor-preview-zoom {
          opacity: 1;
        }
      }
      &.sticked {
        position: fixed;
        top: 80px;
        left: 50%;
        transform: translateX(-50%);
        width: 220px;
        z-index: 20;
      }
    }
  }
  &-items {
    text-align: center;
    font-family: var(--font-oxanium);
    text-transform: uppercase;
    position: relative;
    margin-top: 8px;
    &-row {
      display: flex;
    }
  }
  &-item {
    padding-bottom: 8px;
    padding-top: 8px;
    position: relative;
    border: 1px solid var(--ape-constructor-gray);
    flex: 1;
    margin-right: 5px;
    margin-bottom: 8px;
    border-radius: 5px;
    &:last-child {
      margin-right: 0;
    }
    &-name {
      font-size: 14px;
      color: #454b52;
      margin-bottom: 8px;
    }
    &-value {
      font-size: 16px;
    }

    &-ape-id &-value {
    }
    &-rank {
      &-button {
        color: var(--primary);
        padding: 4px 6px;
        border: 1px solid var(--primary);
        font-weight: normal;
        font-size: 12px;
        border-radius: 5px;
        text-decoration: none;
        line-height: 1.5;
        transition: 0.2s;
        &:hover {
          background-color: var(--primary);
          color: #fff;
        }
      }
    }
    &-rank &-value {
      font-size: 14px;
      color: var(--primary);
    }
  }
  &-actions {
  }
  &-action {
    margin-top: 10px;
  }
  &-button {
    height: 72px;
    width: 100%;
    background-color: var(--primary);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-oxanium);
    font-weight: 900;
    font-size: 24px;
    color: #fff;
    text-decoration: none;
    transition: 0.2s;
    &:hover {
      transform: scale(1.05);
    }
    &.disabled {
      pointer-events: none;
      background-color: #1e1e1e;
      &:hover {
        transform: none;
      }
    }
    &-opensea {
      background-color: var(--color-opensea);
    }
  }

  &-share {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1da1f2;
    padding: 8px;
    text-align: center;
    text-decoration: none;
    box-sizing: border-box;
    margin: 8px 0;
    border-radius: 5px;
    transition: 0.2s;
    &:hover {
      filter: brightness(0.8);
    }
    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
    svg {
      margin-left: 5px;
    }
  }
  &-analyze {
    text-align: center;
    font-family: var(--font-oxanium);
    text-transform: uppercase;
    &-title {
      font-size: 18px;
      margin-bottom: 5px;
      color: var(--ape-constructor-accent);
    }
    &-text {
      font-size: 16px;
      text-transform: initial;
    }
    &-button {
      height: 52px;
      font-size: 16px;
      margin-top: 10px;
    }
  }
  &-placeholder {
    width: 100%;
    padding-bottom: 100%;
  }

  @media screen and (max-width: 1450px) {
    &-wrapper {
      max-width: 380px;
      height: calc(100vh - 80px);
    }
    &-item {
      &-name {
        font-size: 12px;
        margin-bottom: 5px;
      }
    }
  }
  @media screen and (max-height: 930px) {
    &-wrapper {
      height: calc(100vh - 80px);
    }
  }
  @media screen and (max-width: 950px) {
    &-wrapper {
      background: none;
      margin: 0 auto;
      padding: 15px;
      height: auto;
      max-width: 500px;
      width: 100%;
    }
    &-items {
      order: 1;
    }

    &-canvas {
      &-wrapper {
        width: auto;
      }
    }
  }
  @media screen and (max-width: 576px) {
    order: 2;
    &::before,
    &::after {
      display: none;
    }
    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }
    &-canvas-wrapper {
      &.sticked {
        width: 150px;
      }
    }
    &-share {
      width: 150px;
      margin: 0 auto;
      margin-top: 8px;
    }
    &-button {
      height: 55px;
      &:hover {
        transform: none;
        filter: brightness(0.8);
      }
    }
    &-action {
      order: 3;
      margin-top: 20px;
    }
  }
}
</style>
