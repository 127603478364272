import { useApeConstructorStore } from "@/store/ape-constructor";

export const getApeTraitsChanges = () => {
  const apeConstructorStore = useApeConstructorStore();
  const attributes = apeConstructorStore.ape.attributes;
  const equippedTraits = apeConstructorStore.equippedTraits;

  const traitsAllIn = equippedTraits.filter((trait) => {
    const { name, categoryName } = trait;
    const existed =
      attributes.findIndex(
        (attr) =>
          attr.trait_type.toLowerCase() === categoryName.toLowerCase() &&
          attr.value.toLowerCase() === name.toLowerCase()
      ) > -1;
    if (existed) return false;
    return true;
  });
  const traitsAllOut = apeConstructorStore.defaultTraits.filter((trait) => {
    // exclude gender,body type etc
    if (!trait.id) return false;
    const { trait_type, value } = trait;
    const exists =
      equippedTraits.findIndex(
        (attr) =>
          attr.name.toLowerCase() === value.toLowerCase() &&
          attr.categoryName.toLowerCase() === trait_type.toLowerCase()
      ) > -1;
    return !exists;
  });
  const traitsIn = traitsAllIn.map((trait) => trait.id);
  const traitsOut = traitsAllOut
    .filter((trait) => !trait.is_og)
    .map((item) => item.id);
  const traitsOGOut = traitsAllOut
    .filter((trait) => trait.is_og)
    .map((item) => item.id);
  return { traitsAllIn, traitsAllOut, traitsIn, traitsOut, traitsOGOut };
};
