<template>
  <div class="ape-constructor-page">
    <ApeContructor
      v-if="!isLoading"
      :key="currentApe.id"
    />
    <div
      v-else-if="isLoading"
      class="ape-constructor-page-loading"
    >
      <img
        src="@/assets/img/spinner-clear.svg"
        class="loader"
        width="100"
      >
      Loading Ape Configurator
    </div>
  </div>
</template>
<script>
import { computed, defineComponent, onUnmounted, ref, watch } from "vue-demi";
import ApeContructor from "@/components/ApeConstructor/ApeConstructor.vue";
import defaultApes from "@/config/default-apes";
import { useApeConstructorStore } from "@/store/ape-constructor";
import { useWeb3Store } from "@/store/web3";
import { useRoute, useRouter } from "vue-router";
import { usePageWithWallet } from "@/composables/usePageWithWallet";
import { useWalletCollectionStore } from "@/store/wallet-collection";

export default defineComponent({
  components: { ApeContructor },
  props: {
  },
  setup(props) {
    const apeConstructorStore = useApeConstructorStore()
    const walletCollectionStore = useWalletCollectionStore()
    const route = useRoute()
    const router = useRouter()
    const isLoading = ref(true)
    const init = async (connected) => {
      isLoading.value = true
      if (!walletCollectionStore.apes.length && connected) {
        await walletCollectionStore.fetchApes()
      }
      const paramId = route.params.id
      if (paramId === 'any') {
        const apeId = walletCollectionStore.apes.find(ape => !ape.pending && !ape.disableCustomization)?.id
        if (apeId) {
          router.push({ name: "ApeConfigurator", params: { id: apeId } })
        } else {
          router.push({ name: "ApeConfigurator", params: { id: 1 } })
        }
        return
      }
      const apeId = +paramId
      const ape = walletCollectionStore.apes.length > 0 ? walletCollectionStore.apes.find(ape => ape.id === apeId) : defaultApes.find(ape => ape.id === apeId)

      if (!apeId || !ape || ape.pending || ape.disableCustomization) {
        router.push({ name: "Collection" })
        return
      }
      if (apeConstructorStore.ape?.id !== ape.id) {
        apeConstructorStore.$reset()
        apeConstructorStore.init(ape)
      }
      isLoading.value = false
    }
    const currentApe = computed(() => useApeConstructorStore().ape || {})
    watch(() => route.params, () => {
      // update on change param id, staying on ape constructor page
      init()
    })
    const { isConnected } = usePageWithWallet({
      onInit: (connected) => {
        if (!connected && (route.params.id !== "1" && route.params.id !== '2')) {
          router.push({ name: "ApeConfigurator", params: { id: 1 } })
          return
        }
        init(connected)
      },
      onWalletChange: async () => {
        await walletCollectionStore.fetchApes()
        init()
      }
    })
    return { isConnected, isLoading, currentApe }
  },

})
</script>
<style lang="scss">
.ape-constructor-page {
  &-loading {
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    img {
      margin-bottom: 10px;
    }
  }
  @media screen and (max-width: 576px) {
    &-loading {
      padding: 15px;
      font-size: 18px;
    }
  }
}
</style>