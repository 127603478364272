<template>
  <div class="ape-constructor-my-traits">
    <div class="ape-constructor-my-traits__sort-title">
      Sort
    </div>
    <ASelect
      v-model:value="activeSort"
      class="ape-constructor-my-traits__sort"
      :items="sortItems"
    />
    <div class="ape-constructor-my-traits__items">
      <a
        v-for="item in items"
        :key="item.id"
        href="#"
        class="ape-constructor-my-traits__item"
        @click.prevent="$emit('update:value', item)"
      >
        <ApeConstructorTraitCard
          :is-equipped="isEquipped(item)"
          :item="item"
          :show-category="true"
          @openInfo="$emit('openInfo', item)"
        />
      </a>
      <InfiniteLoading @infinite="loadMore" />
    </div>
  </div>
</template>
<script setup>
import { useApeConstructorStore } from "@/store/ape-constructor";
import { computed, ref } from "vue-demi";
import ApeConstructorTraitCard from "../ApeConstructorTraitCard/ApeConstructorTraitCard.vue"
import InfiniteLoading from "v3-infinite-loading";
import ASelect from "@/components/ASelect.vue";
import { ApeTraitRarity } from "@/helpers/ape-constructor/ape-trait-rarity";

const store = useApeConstructorStore()
const isEquipped = (trait) => {
    return store.equippedTraits.findIndex(item => item.id == trait.id) > -1
}
const traits = computed(() => {
    return store.walletTraits
})
const activeSort = ref('rarity_asc')
const sortItems = [
    {
        name: "Rarity: High to Low",
        value: "rarity_asc"
    },
    {
        name: "Rarity: Low to High",
        value: "rarity_desc"
    },
    {
        name: "Total Minted: High to Low",
        value: "total_minted_asc"
    },
    {
        name: "Total Minted: Low to High",
        value: "total_minted_desc"
    },
    {
        name: "Trait Balance: Hight to Low",
        value: "balance_asc"
    },
    {
        name: "Trait Balance: Low to High",
        value: "balance_desc"
    },
]
const page = ref(1)
const perPage = 30
const items = computed(() => {
    const rarityOrder = [ApeTraitRarity.og, ApeTraitRarity.common, ApeTraitRarity.epic, ApeTraitRarity.legendary]

    const sorted = [...traits.value].sort((a, b) => {
        if (activeSort.value === 'rarity_asc') {
            return rarityOrder.indexOf(b.rarity) - rarityOrder.indexOf(a.rarity)
        } else if (activeSort.value === 'rarity_desc') {
            return rarityOrder.indexOf(a.rarity) - rarityOrder.indexOf(b.rarity)
        } else if (activeSort.value === 'total_minted_asc') {
            return b.total_minted - a.total_minted
        } else if (activeSort.value === 'total_minted_desc') {
            return a.total_minted - b.total_minted
        } else if (activeSort.value === 'balance_asc') {
            return ((b.balance || 0) + (b.offChain || 0)) - ((a.balance || 0) + (a.offChain || 0))
        } else if (activeSort.value === 'balance_desc') {
            return ((a.balance || 0) + (a.offChain || 0)) - ((b.balance || 0) + (b.offChain || 0))
        }
    })
    return sorted.slice(0, page.value * perPage)
})
const loadMore = () => {
    page.value++
}
</script>
<style lang="scss">
.ape-constructor-my-traits {
    padding-right: 15px;
    &__sort {
        margin-bottom: 15px;
        min-width: 250px;

        .app-select-header {
            font-size: 14px;
            white-space: nowrap;
        }
        &-title {
            font-style: 12px;
            text-transform: uppercase;
            color: rgba(255, 255, 255, 0.5);
            margin-bottom: 4px;
        }
    }

    &__items {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -9px;
        overflow: auto;
    }
    &__item {
        width: calc(100% / 5 - 18px);
        margin: 14px 9px;
        box-sizing: border-box;
        text-decoration: none;
    }
    @media screen and (max-width: 1880px) {
        &__item {
            width: calc(100% / 4 - 18px);
        }
    }
    @media screen and (max-width: 1100px) {
        &__item {
            width: calc(100% / 3 - 18px);
        }
    }
    @media screen and (max-width: 760px) {
        padding-right: 0px;

        &__item {
            width: calc(100% / 2 - 18px);
        }
    }
}
</style>