<template>
  <div class="ape-constructor">
    <div class="ape-constructor-row">
      <div class="ape-constructor-content">
        <ApeConstructorTitle />

        <ApeConstructorNavigation />
        <div class="ape-constructor-content-elements">
          <!-- <ApeConstructorBack /> -->
          <ApeConstructorHint />
          <ApeConstructorSearch v-model:value="searchPhrase" />
          <template v-if="isSearch">
            <ApeConstructorSearchItems
              :search="searchPhrase"
              @openInfo="openTraitInfo"
            />
          </template>
          <template v-else>
            <ApeConstructorEquippedTraits v-model:activeCategoryId="activeCategoryId" />

            <ApeConstructorTabs
              v-model:tab="tab"
              :active-category="activeCategory.name"
              @back="goBack"
            />

            <template v-if="tab === 'traits'">
              <ApeConstructorTraits
                v-if="activeCategoryId"
                :key="activeCategoryId"
                :category-id="activeCategoryId"
                @back="goBack"
                @update:value="onChangeTrait"
                @openInfo="openTraitInfo"
              />
              <ApeConstructorCategories
                v-else
                v-model:value="activeCategoryId"
              />
            </template>
            <ApeConstructorMyTraits
              v-else
              @update:value="onChangeTrait"
              @openInfo="openTraitInfo"
            />
          </template>
        </div>
      </div>
      <div class="ape-constructor-right">
        <ApeConstructorTitle />
        <!-- <ApeConstructorBack /> -->
        <ApeConstructorNavigation />
        <ApeConstructorTraitInfo
          v-if="showTraitInfo"
          :item="activeTraitInfo"
          @close="closeTraitInfo"
        />
        <ApeContructorPreview v-else />
      </div>
    </div>
    <div class="ape-constructor-shape-top-wrapper">
      <img
        src="@/assets/img/ape-constructor/shape-top.png"
        alt="shape-top"
        class="ape-constructor-shape-top"
      >
    </div>
  </div>
</template>
<script>
import { computed, defineComponent, ref } from "vue";
import ApeContructorPreview from "./ApeContructorPreview/ApeContructorPreview.vue";
import { useApeConstructorStore } from "@/store/ape-constructor";
import ApeConstructorTraits from "./ApeConstructorTraits/ApeConstructorTraits.vue";
import ApeConstructorCategories from "./ApeConstructorCategories/ApeConstructorCategories.vue";
import ApeConstructorTitle from "./ApeConstructorTitle.vue";
import ApeConstructorSearch from "./ApeConstructorSearch/ApeConstructorSearch.vue";
import ApeConstructorBreadcrumbs from "./ApeConstructorBreadcrumbs.vue";
import ApeConstructorEquippedTraits from "./ApeConstructorEquippedTraits/ApeConstructorEquippedTraits.vue";
import ApeConstructorTraitInfo from "./ApeConstructorTraitInfo/ApeConstructorTraitInfo.vue";
import ApeConstructorSearchItems from "./ApeConstructorSearch/ApeConstructorSearchItems.vue";
import ApeConstructorBodyType from "./ApeConstructorBodyType/ApeConstructorBodyType.vue";
import ApeConstructorBack from "./ApeConstructorBack/ApeConstructorBack.vue";
import { useToast } from "@/composables/useToast";
import { useModal } from "@/composables/useModal";
import { ModalName } from "../Modal/modal-name";
import ApeConstructorNavigation from "./ApeConstructorNavigation/ApeConstructorNavigation.vue";
import ApeConstructorHint from "./ApeConstructorHint.vue";
import ApeConstructorTabs from "./ApeConstructorTabs/ApeConstructorTabs.vue";
import ApeConstructorMyTraits from "./ApeConstructorMyTraits/ApeConstructorMyTraits.vue";

export default defineComponent({
  components: { ApeContructorPreview, ApeConstructorMyTraits, ApeConstructorTabs, ApeConstructorHint, ApeConstructorNavigation, ApeConstructorBack, ApeConstructorBodyType, ApeConstructorSearchItems, ApeConstructorTraitInfo, ApeConstructorSearch, ApeConstructorEquippedTraits, ApeConstructorBreadcrumbs, ApeConstructorTitle, ApeConstructorTraits, ApeConstructorCategories },
  setup() {
    const store = useApeConstructorStore()
    const activeCategoryId = ref(undefined)
    const searchPhrase = ref('')
    const activeTraitInfo = ref()
    const showTraitInfo = computed(() => !!activeTraitInfo.value)
    const isSearch = computed(() => !!searchPhrase.value)
    const activeCategory = computed(() => {
      return store.traitCategories.find(cat => cat.id === activeCategoryId.value) || {}
    })
    const bodyTypeId = computed(() => store.bodyTypeId)
    const tab = ref('traits')
    store.fetchWalletTraits()

    const goBack = () => {
      activeCategoryId.value = undefined
    }
    const openTraitInfo = (trait) => {
      activeTraitInfo.value = trait
    }
    const closeTraitInfo = () => {
      activeTraitInfo.value = ''
    }
    const onChangeTrait = (trait) => {
      store.toggleTrait(trait)
      closeTraitInfo()
    }
    return {
      activeCategory, activeTraitInfo, isSearch,
      openTraitInfo, showTraitInfo, activeCategoryId,
      onChangeTrait, goBack, searchPhrase,
      bodyTypeId, closeTraitInfo, tab
    }
  }
})
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,700&family=Oxanium:wght@700;800&display=swap");
.ape-constructor {
  font-family: var(--font-montserrat), sans-serif;
  font-weight: bold;
  background-color: var(--ape-constructor-bg);
  height: 100%;
  min-height: 100vh;
  &-row {
    display: flex;
    align-items: stretch;
    max-width: 1920px;
    margin: 0 auto;
  }
  &-content {
    position: relative;
    z-index: 10;
    min-width: 0;
    /*width: 830px; */
    width: 1200px;
    margin-right: 50px;
    &-elements {
      padding-left: 40px;
    }
  }
  &-right {
    min-width: 350px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 20;

    flex: 1;

    .ape-constructor-title-wrapper,
    .ape-constructor-navigation {
      display: none;
    }
  }
  &-shape-top {
    &-wrapper {
      position: absolute;
      pointer-events: none;
      z-index: 0;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      overflow: hidden;
    }
    position: absolute;
    top: -500px;
    left: 00%;
  }

  &-preview {
  }
  @media screen and (max-width: 1880px) {
    &-content {
      width: 830px;
    }
  }
  @media screen and (max-width: 950px) {
    &-row {
      flex-direction: column;
    }
    &-content {
      order: 2;
      width: 100%;
      margin-right: 0;
      margin-top: 50px;
      &-elements {
        padding: 0 15px;
      }
      .ape-constructor-title-wrapper {
        display: none;
      }
      .ape-constructor-navigation {
        display: none;
      }
    }

    &-right {
      order: 1;
      min-width: 0;
      flex-direction: column;
      .ape-constructor-title-wrapper {
        display: block;
      }
      .ape-constructor-navigation {
        display: block;
      }
    }
    &-shape-top {
      display: none;
    }
  }
}
.ape-constructor-toast-link-resolve {
  color: #fff !important;
  padding: 5px 10px;
  border-radius: 5px;
  text-transform: uppercase;
  text-decoration: none;
  box-sizing: border-box;
  margin-top: 5px;
  display: inline-block;
  font-weight: bold;
  background-color: var(--primary);
  transition: 0.2s;
  &:hover {
    filter: brightness(0.8);
  }
}
</style>