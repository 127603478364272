<template>
  <div class="ape-constructor-body-type">
    <div class="ape-constructor-body-type-title">
      Body Type
    </div>
    <div class="ape-constructor-body-type-switcher-wrapper">
      <div
        class="ape-constructor-body-type-label"
        :class="{ active: !isSkin }"
      >
        FUR
      </div>
      <ASwitcher
        v-model:value="isSkin"
        class="ape-constructor-body-type-switcher"
      />
      <div
        class="ape-constructor-body-type-label"
        :class="{ active: isSkin }"
      >
        SKIN
      </div>
    </div>
  </div>
</template>
<script>import { defineComponent, watch, ref, computed } from "vue-demi";
import ASwitcher from "@/components/ASwitcher.vue";
import { useApeConstructorStore } from "@/store/ape-constructor";
import { useConfirmModal } from "@/composables/useConfirmModal";


export default defineComponent({
  components: { ASwitcher },
  setup() {
    const store = useApeConstructorStore()
    const isSkin = computed({
      get() {
        // Fur = 1
        return store.bodyTypeId === 2
      },
      async set(val) {
        const confirm = await useConfirmModal({
          title: "Reset alert",
          text: "Changing Body Type will reset current equipped traits",
          confirmText: "Continue"
        })
        if (!confirm) return
        if (val) {
          store.switchBodyType(2)
        } else {
          store.switchBodyType(1)
        }
        store.resetEquippedTraits()
      }
    })


    return {
      isSkin,
    }
  }
})
</script>
<style lang="scss">
.ape-constructor-body-type {
  border-bottom: 2px solid #141418;
  margin-bottom: 24px;
  &-title {
    color: #fff;
    margin-bottom: 9px;
  }
  &-switcher {
    margin: 0 10px;

    .app-switcher {
      height: 32px;
      width: 56px;
      background-color: #1e1e1e;
      border: none;
      span {
        width: 16px;
        height: 16px;
        background-color: var(--primary);
        transform: translateX(8px);
        position: relative;
        top: 8px;
      }
      &.active {
        background-color: #1e1e1e;

        span {
          transform: translateX(32px);

          background-color: var(--primary);
        }
      }
    }
    &-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
    }
  }
  &-label {
    color: var(--ape-constructor-gray);
    &.active {
      color: var(--primary);
    }
  }
}
</style>