export default [{
  attributes: [
    {
      trait_type: "Gender",
      value: "Female",
    },
    {
      trait_type: "Body Type",
      value: "Skin",
    },
    {
      trait_type: "Guild",
      value: "North",
    },
  ],
  id: 1,
  originalId: 1,
  name:"Female"
},{
  attributes: [
    {
      trait_type: "Gender",
      value: "Male" ,
    },
    {
      trait_type: "Body Type",
      value: "Skin",
    },
    {
      trait_type: "Guild",
      value: "North",
    },
  ],
  id: 2 ,
  originalId: 2,
  name:"Male"
}]
